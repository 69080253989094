<template>

	<div class="cbox">
		<div class="headbar">
			<div style="padding-top: 14px;">
				<el-breadcrumb>
					<el-breadcrumb-item>公告</el-breadcrumb-item>
					<el-breadcrumb-item>公告列表</el-breadcrumb-item>
					<el-breadcrumb-item>公告编辑</el-breadcrumb-item>
				</el-breadcrumb>

			</div>

		</div>
		<div style="height: 85%;">
			<el-tabs v-model="activeName">
				<el-tab-pane label="直接填写" name="first"></el-tab-pane>
				<el-tab-pane label="从公众号文章提取" name="second"></el-tab-pane>
				<el-tab-pane label="上传WORD文件" name="third"></el-tab-pane>

			</el-tabs>
			<div style="max-width: 800px; margin: 0 auto;">

				<fieldset style="border:1px solid  #ccc; " v-if="activeName == 'second'">
					<legend>从公众号文章提取</legend>
					<div style="">
						<div style="padding-bottom: 20px;">
							<el-input v-model="wxurl" style="width: 500px;" placeholder="请输入公众号文章地址"></el-input>
							<el-button style="margin-left: 10px;" @click="getWxArt">提取</el-button>
						</div>

					</div>
				</fieldset>

				<fieldset style="border:1px solid  #ccc; " v-if="activeName == 'third'">
					<legend>上传word文件</legend>
					<div style="">
						<div style="padding-bottom: 20px;">
							<el-input readonly v-model="wxurl" style="width: 500px;" placeholder="请输入选择word文件">
							</el-input>

							<el-upload style="display: inline-block;" action="" :multiple="true"
								:http-request="uploadTemp" :on-success="handleWordUpload" :show-file-list="false"
								name="image">
								<el-button style="margin-left: 10px;" icon="el-icon-upload">选择Word/Pdf文件</el-button>

							</el-upload>
						</div>

					</div>
				</fieldset>

				<fieldset style="border:1px solid  #ccc; background-color: #fff; ">
					<legend>编辑公告详情</legend>
					<el-form :model="EditItem" ref="EditItem" label-width="120px">
						<el-row style="padding-top: 20px;">
							<el-col :span="24">
								<el-form-item label="标题" required>
									<el-input style="width: 90%;" v-model="EditItem.title" placeholder="请输入标题">
									</el-input>

								</el-form-item>
							</el-col>
						</el-row>
						<el-row>
							<el-col :span="24">
								<el-form-item label="头图">
									<div style="padding-left: 0px;">
										<img v-if="EditItem.image !== ''" :src="EditItem.image" width="125" />
										<el-upload action="" :multiple="true" :http-request="uploadOss"
											:on-success="handleAvatarSuccess" :on-progress="handleUploadProcess"
											:show-file-list="false" name="image">
											<el-button style="margin-left: 20px; vertical-align: middle;" size="small"
												@click="dialogImageVisible = true" icon="el-icon-picture-outline-round">
												选择
											</el-button>
										</el-upload>



									</div>

								</el-form-item>
							</el-col>
						</el-row>
						<!-- <el-row style="padding-top: 20px;">
							<el-form-item label="请选视频">
								<el-upload action="" :multiple="true" :http-request="uploadOss"
									:on-success="handleAvatarSuccess" :on-progress="handleUploadProcess"
									:show-file-list="false" name="image">
									<video v-if="EditItem.video" :src="EditItem.video" class="avatar"
										style="width: 200px;" controls="controls"></video>
									<i class="el-icon-plus avatar-uploader-icon"></i>
								</el-upload>


								<div id="videoprocess"
									style="width: 80%; height: 16px; border: 1px solid #f6f6f6; border-radius: 8px; position: relative; display: none; ">
									<div id="videoprocess_width"
										style="width: 0%; height: 100%;background:#ff6b02;border-radius: 8px;"></div>
									<div id="videoprocess_txt"
										style="width: 100%; text-align: center; height: 100%;border-radius: 8px; position: absolute; top: 0; line-height: 18px; color: #888; font-size: 12px;">
										0%</div>


								</div>

							</el-form-item>
						</el-row> -->


						<el-row style="padding-top: 20px;">
							<el-col :span="24">
								<el-form-item label="类型">

									<el-select v-model="EditItem.type_name" filterable allow-create default-first-option
										placeholder="请选择或输入通知分类">
										<el-option v-for="item in TypeList" :key="item.id" :label="item.type_name"
											:value="item.type_name">
										</el-option>
									</el-select>
									<span style="color: orangered; margin-left: 20px;">*</span>


								</el-form-item>
							</el-col>
						</el-row>
						<!-- <el-row style="padding-top: 20px;">
							<el-col :span="24">
								<el-form-item label="发送对象">

									<el-button size="small" v-if="!sendToAll" style="margin-right: 20px;"
										@click="showTarget">
										{{ choosedTeachers > 0 ? '教师' + choosedTeachers + '人' : '' }}
										{{ choosedClasses > 0 ? '班级' + choosedClasses + '个' : '' }}
										{{ choosedClasses == 0 && choosedTeachers == 0 ? '手动选择' : '' }}
									</el-button>

									<el-checkbox v-if="isAdmin" v-model="sendToAll">全部人员</el-checkbox>

									<el-checkbox v-if="isAdmin" v-model="allTeacher">对所有内部教职工</el-checkbox>

									<el-checkbox v-if="isAdmin" v-model="allStudent">对所有学生</el-checkbox>

								</el-form-item>
							</el-col>
						</el-row> -->

						<!-- <el-row style="padding-top: 20px;">
							<el-col :span="24">
								<el-form-item label="弹窗提醒">



									<el-checkbox v-model="EditItem.alert">开启弹窗提醒</el-checkbox>

									<el-tooltip class="item" effect="dark" content="未读的公告会在首页弹窗提醒用户阅读"
										placement="top-start">
										<i class="el-icon-question"></i>
									</el-tooltip>

								</el-form-item>
							</el-col>
						</el-row> -->

						<!-- <el-row style="padding-top: 20px;">
							<el-col :span="24">
								<el-form-item label="阅读后">


									<el-checkbox v-model="EditItem.need_confirm">需要点击确认收到</el-checkbox>

									<el-checkbox v-model="EditItem.need_sign_pic">需要签名</el-checkbox>

									<el-checkbox v-model="EditItem.need_zipai">需要拍照</el-checkbox>

									<el-tooltip class="item" effect="dark" content="勾选[需要签名]或[需要拍照],默认[需要点击确认收到]"
										placement="top-start">
										<i class="el-icon-question"></i>
									</el-tooltip>



								</el-form-item>
							</el-col>
						</el-row> -->
						<el-row style="padding-top: 20px;" v-if="needSH">
							<el-col :span="24">
								<el-form-item label="审核人">

									<el-button size="small" style="margin-right: 20px;" @click="showSHTarget">选择审核人
									</el-button>



								</el-form-item>
							</el-col>
						</el-row>
						<el-row style="padding-top: 20px;">
							<el-col :span="24">
								<el-form-item label="提醒方式">

									<el-checkbox v-model="sendMiniTemp">小程序推送</el-checkbox>
									<!-- <el-checkbox v-model="sendEmailTemp">邮件推送</el-checkbox> -->
									<el-checkbox :disabled="true" v-model="sendSmsTemp">短信推送</el-checkbox>



								</el-form-item>
							</el-col>
						</el-row>

						<el-row style="padding-top: 20px;">
							<el-col :span="24">
								<el-form-item label="附件">
									<div>
										<div class="fileitem" v-for="(f, idx) in EditItem.files">
											{{ f.fileName }}
											<div class="remove" @click="removeAtt(idx)"></div>
										</div>
									</div>
									<el-upload action="" :multiple="true" :http-request="uploadOss"
										:on-success="uploadAttch" :show-file-list="false" name="image">

										<el-button size="mini">+</el-button>
									</el-upload>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row style="padding-top: 20px;">
							<el-col :span="24">
								<el-form-item label="外链">
									<el-input v-model="EditItem.outhref" placeholder="没有请留空"></el-input>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row style="padding-top: 20px;">
							<el-col :span="24">
								<el-form-item label="内容">

									<div style="line-height: initial; width: 100%; ">
										<UEditor ref="ue" @ready="editorReady" style="width: 100%; "></UEditor>
									</div>
								</el-form-item>
							</el-col>
						</el-row>



					</el-form>
					<el-row>
						<el-col :span="22" style="text-align: right;">
							<el-checkbox v-model="EditItem.sendmsg" style="margin-right: 50px;">立即发送通知</el-checkbox>
							<el-button @click="saveForm(0)" icon="el-icon-document">保存草稿</el-button>
							<el-button type="primary" icon="el-icon-s-promotion" @click="saveForm(1)">
								{{ needSH? '提交审核': '发布' }}
							</el-button>

							<el-button @click="previewPhone" icon="el-icon-mobile">预览</el-button>
						</el-col>
					</el-row>

				</fieldset>
				<div style="height: 100px;"></div>
			</div>



			<deptuser v-if="dialogVisible" :oteachers="EditItem.target_teachers" :oclasses="EditItem.target_classes">
			</deptuser>





			<div id="wxhtml" style="width: 0; height: 0; overflow: hidden;"></div>
		</div>

		<el-dialog title="手机预览" :visible.sync="dialogYLVisible" width="240px">

			<vue-qr :text="'https://sx.qushixi.org.cn/h5/m.html?id=' + EditItem.id" :margin="0" colorDark="#000000"
				colorLight="#fff" :size="200"></vue-qr>
			<div style="text-align: center; line-height: 50px;">
				使用微信扫一扫预览
			</div>

		</el-dialog>

		<div id="wxcontent" style="width: 100%; height: 0; overflow: hidden;"></div>

	</div>
</template>

<script>
import UEditor from './ueditor.vue'
import deptuser from '../com/deptuser/deptuser.vue'
import vueQr from 'vue-qr'
export default {
	components: {
		UEditor,
		deptuser,
		vueQr
	},
	data() {
		return {
			wxurl: "",
			dialogVisible: false,
			dialogImageVisible: false,
			dialogYLVisible: false,
			editor: null,
			activeName: "first",
			checkedAllDept: false,
			checkedAllClass: false,
			sendToAll: true, //发送给所有用户
			SHdialog: false,
			EditItem: {
				title: '',
				image: "",
				status: 1,
				type_name: "",
				create_uid: 0,
				id: 0,
				target_teachers: "", //部门教师
				target_classes: "", //班级

				content: "",
				files: [],
				outhref: "",
				sendmsg: true,
				video: "",
				need_zipai: false,
				need_sign_pic: false,
				need_confirm: false,
				alert: false,

			},
			allStudent: false,
			allTeacher: false,
			defaultProps: {
				children: 'children',
				label: 'name'
			},
			TypeList: [{
				id: 1, type_name: "公示公告"
				
			}, {
				id: 2, type_name: "工作要求"
				
			}],
			DeptData: [],
			SHDeptData: [],
			ClassData: [],
			userinfo: null,
			sendMiniTemp: true,
			sendSmsTemp: false,
			sendEmailTemp: false,
			articleImage: [],
			needSH: false,
			idx2idDic: {},
			targetTeachers: [],
			targetUser: {
				targetTeachers: [],
				targetClasses: []
			},
			choosedTeachers: 0,
			choosedClasses: 0,
			teachersAll: false, //对象教师全选
			classesAll: false, //对象班级全选
			isAdmin: false,

		}
	},
	mounted() {
		console.log(this.$route.query.id)
		this.getUserRoles()
		setTimeout(() => {
			if (this.$route.query.id > 0) {
				this.EditItem.id = this.$route.query.id;
				this.getInfo()
			}
		}, 500)


		//this.getTypeList();


	},
	methods: {
		getUserRoles() {
			this.$http.post("/api/get_teacher_info", { stu_id: this.student_id }).then(res => {
				if (res.data && res.data.sys_roles) {
					res.data.sys_roles.map(a => {
						if (a.role_code.indexOf('admin') > -1) {
							this.isAdmin = true
						}
					})
				}
			})
		},
		getInfo() {
			this.$http.post('/api/notify_info', {
				id: this.EditItem.id
			}).then(res => {
				res.data.files = JSON.parse(res.data.files)


				this.editor.setContent(res.data.content)

				res.data.need_confirm ? res.data.need_confirm = true : res.data.need_confirm = false;
				res.data.need_zipai ? res.data.need_zipai = true : res.data.need_zipai = false;
				res.data.need_sign_pic ? res.data.need_sign_pic = true : res.data.need_sign_pic = false;
				res.data.alert ? res.data.alert = true : res.data.alert = false;

				this.EditItem = res.data

				setTimeout(() => {
					if (this.EditItem.target_classes == "all") {
						this.allStudent = true
					}
					if (this.EditItem.target_teachers == "all") {
						this.allTeacher = true
					}
					if (this.allStudent && this.allTeacher) {
						this.sendToAll = true
					}
					if (this.EditItem.target_teachers.indexOf(",") > 0) {
						this.choosedTeachers = this.EditItem.target_teachers.split(",").length - 2
					}
					if (this.EditItem.target_classes.indexOf(",") > 0) {
						this.choosedClasses = this.EditItem.target_classes.split(",").length - 2
					}








				}, 500)

			});
		},
		setImage(url) {
			if (url) {
				this.EditItem.image = url
			}
			this.dialogImageVisible = false

		},
		getTypeList() {
			this.$http.post('/api/notify_type_list', {}).then(res => {
				this.TypeList = res.data.data;
				//this.getDeptUser();
				// this.getClasses();
			});
		},



		editorReady(editorInstance) {

			editorInstance.setContent(this.EditItem.content);
			editorInstance.addListener('contentChange', () => {
				this.EditItem.content = editorInstance.getContent();
			});
			this.editor = editorInstance;
		},
		uploadCover(e) {

			this.EditItem.image = e.src
		},
		uploadAttch(e) {

			this.EditItem.files.push(e)
		},
		removeAtt(idx) {
			this.EditItem.files.splice(idx, 1)
		},
		saveForm(status) {
			if (!this.EditItem.image) {
				this.$message({
					type: 'error',
					message: '请选择头图'
				});
				return false
			}

			if (!this.EditItem.type_name) {

				this.$message({
					type: 'error',
					message: '请选择类型'
				});
				return false
			}
			if (!this.EditItem.title) {
				this.$message({
					type: 'error',
					message: '请输入标题'
				});
				return false
			}

			if (this.sendToAll) {
				this.EditItem.target_teachers = "all";
				this.EditItem.target_classes = "all";
			} else {
				//发送对象
				if (this.allStudent) {
					this.EditItem.target_classes = "all"
				}

				if (this.allTeacher) {
					this.EditItem.target_teachers = "all"
				}



			}
			if (this.needSH) {
				//审核用户

			} else {
				this.EditItem.shteachers = ""
			}







			this.EditItem.remindtype = "";
			if (this.sendMiniTemp) {
				this.EditItem.remindtype += "mini"
			}
			if (this.sendEmailTemp) {
				this.EditItem.remindtype += "email"
			}
			if (this.sendSmsTemp) {
				this.EditItem.remindtype += "sms"
			}





			if (this.needSH && status == 1) {
				this.EditItem.status = 2; //待审核
			} else {
				this.EditItem.status = status;
			}

			this.EditItem.content = this.editor.getContent();
			let item = JSON.parse(JSON.stringify(this.EditItem));
			item.files = JSON.stringify(item.files);



			item.need_zipai = item.need_zipai ? 1 : 0
			item.need_sign_pic = item.need_sign_pic ? 1 : 0
			item.need_confirm = item.need_confirm ? 1 : 0
			item.alert = item.alert ? 1 : 0


			this.$http.post('/api/notify_list_edit', item).then(res => {
				this.$message({
					type: 'success',
					message: '保存成功'
				});
				if (res.data.id) {
					this.EditItem.id = res.data.id
				}
				if (status > 0) {
					setTimeout(() => {
						this.$router.push("/notify")
					}, 2000)
				}


			});

			return true

		},
		setTarget(e) {
			if (e.teachers && e.teachers.length > 0) {
				this.choosedTeachers = e.teachers.length
				this.EditItem.target_teachers = "0," + e.teachers.join(',') + ",0"
			} else {
				this.EditItem.target_teachers = "0"
				this.choosedTeachers = 0
			}

			if (e.classes && e.classes.length > 0) {
				this.EditItem.target_classes = "0," + e.classes.join(',') + ",0"
				this.choosedClasses = e.classes.length
			} else {
				this.EditItem.target_classes = "0"
				this.choosedClasses = 0
			}


		},
		showTarget() {
			this.dialogVisible = true;
		},
		setCheckAllDept() {

			if (this.checkedAllDept) {
				this.$refs['dept_tree'].setCheckedKeys(this.DeptData.map(e => {

					return e.idx
				}));
				this.EditItem.target_teachers = "all"
			} else {
				this.$refs['dept_tree'].setCheckedKeys([]);
			}
		},
		setCheckAllClass() {
			if (this.checkedAllClass) {
				this.$refs['class_tree'].setCheckedKeys(this.ClassData.map(e => {
					return e.idx
				}));
			} else {
				this.$refs['class_tree'].setCheckedKeys([]);
			}
		},
		setDeptNodeCheck() {
			this.checkedAllDept = false
		},
		setClassNodeCheck() {
			this.checkedAllClass = false
		},
		handleAvatarSuccess(res, file) {
			this.EditItem.image = res.src
		},
		showSHTarget() {
			this.SHdialog = true
		},
		handleUploadProcess(e) {

			// $("#videoprocess").show();
			// let pct = parseInt(e.loaded / e.total * 1000) / 10;
			// $("#videoprocess_txt").text(pct + "%");
			// $("#videoprocess_width").css("width", pct + "%");
			// if (pct >= 100) {
			// 	$("#videoprocess").hide();
			// }
		},
		handleWordUpload(e) {
			this.$http.post(window.location.protocol + "//api-sch-saas.aiwx.org.cn/api/word2images", {
				url: e.src
			}).then(res => {
				let images = res.data;
				let html = '';
				for (let img of images) {
					html += '<img src="' + img + '" />'
				}
				this.editor.setContent(html)
			})
		},
		previewPhone() {
			if (this.EditItem.id) {
				this.dialogYLVisible = true
			} else {
				if (this.saveForm(0)) {
					setTimeout(() => {
						this.dialogYLVisible = true
					}, 1000)
				}


			}

		},
		getWxArt() {
			this.$http.post("/api/getWxArticleContent", {
				url: this.wxurl
			}).then(res => {

				$("#wxcontent").html(res.data.html)
				$("img", "#wxcontent").each((idx, ele) => {
					$(ele).attr("src", "//api-sch-saas.aiwx.org.cn/api/image?src=" + ($(ele).attr(
						"data-src")))
				})
				if (res.data.html) {
					this.$message.success("已读取文章内容")
				}
				setTimeout(() => {
					this.EditItem.content = $("#wxcontent").html();
					this.editor.setContent(this.EditItem.content)
				}, 1000)
			})
		}
	}
}
</script>

<style scoped>
.fileitem {
	border: 1px solid #ccc;
	line-height: 20px;
	padding: 4px 10px;
	display: inline-block;
	border-radius: 4px;
	position: relative;
	margin-right: 10px;
	margin-bottom: 6px;
}

.remove {
	width: 16px;
	height: 16px;
	background: url(../../../public/img/remove.png);
	background-size: 100% 100%;
	position: absolute;
	top: -6px;
	right: -6px;
	background-color: #FFFFFF;
	cursor: pointer;

}
</style>
<style type="text/css" scoped>
.el-tabs__nav-scroll {
	text-align: center;
}

.el-tabs__nav {
	display: inline-block;
	float: none;
}
</style>
